var Tegeldepot = Tegeldepot || {};

Tegeldepot.QtyInput = class {
    constructor(options) {
        this.addButtons = this.addButtons.bind(this);
        this.qtyUp = this.qtyUp.bind(this);
        this.qtyDown = this.qtyDown.bind(this);
        this.buttonFunctionality = this.buttonFunctionality.bind(this);

        this.options = jQuery.extend({}, {
            qtySelector: 'input.qty',
            btnsHtml: '<div class="qty-nav"><a href="#" class="qty-btn up"></a><a href="#" class="qty-btn down"></a></div>',
            wrapperHtml: '<div class="qty-wrapper"></div>'
        }, options);

        this.addButtons();
    }

    qtyUp(qtyInput) {
        const val = qtyInput.val() || 0,
            oldValue = parseFloat(val),
            max = qtyInput.attr('max');
        let newVal = 0;
        if (oldValue >= max) {
            newVal = oldValue;
        } else {
            newVal = oldValue + 1;
        }

        qtyInput.val(newVal);
        qtyInput.trigger("change");
    }

    qtyDown(qtyInput) {
        const val = qtyInput.val() || 0,
            oldValue = parseFloat(val),
            min = qtyInput.attr('min');

        let newVal = 0;
        if (oldValue <= min) {
            newVal = oldValue;
        } else {
            newVal = oldValue - 1;
        }

        qtyInput.val(newVal);
        qtyInput.trigger("change");
    }

    buttonFunctionality() {
        jQuery(this.options.qtySelector).each((index, element) => {
            const qtyInput = jQuery(element),
                btnUp = qtyInput.parents('.qty-wrapper').find('.up'),
                btnDown = qtyInput.parents('.qty-wrapper').find('.down');

            jQuery(btnUp).click((e) => {
                e.preventDefault();
                this.qtyUp(qtyInput);
            });

            jQuery(btnDown).click((e) => {
                e.preventDefault();
                this.qtyDown(qtyInput);
            });

        });
    }

    addButtons() {
        jQuery(this.options.qtySelector).wrap(this.options.wrapperHtml).after(this.options.btnsHtml);

        this.buttonFunctionality();
    }

};

jQuery(function () {
    new Tegeldepot.QtyInput();
});
